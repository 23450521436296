import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./dashboard.css";
function Dashboard({ enquiries }) {
  const handlelogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const [isMobile, setIsMobile] = useState(false);
  const [openMenus, setOpenMenus] = useState({
    lead: false,
    employee: false,
    branch: false,
    client: false,
    product: false,
    project: false,
  });

  const toggleMenu = (menu) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const handleSidebarSizeChange = (event) => {
    // Set the attribute 'data-sidebar-size' to 'lg' on the body element
    document.body.setAttribute("data-sidebar-size", "lg");
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://www.coinoneglobal.com/coinone/count.php")
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);


  return (
    <div>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <a href="#" className="logo logo-dark">
                <span className="logo-sm">
                  <img
                    src="https://coinoneglobal.com/assets/img/coinone.png"
                    alt
                    height={24}
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src="https://coinoneglobal.com/assets/img/coinone.png"
                    alt
                    height={24}
                  />{" "}
                  <span className="logo-txt">Coinone</span>
                </span>
              </a>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className=" fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item bg-soft-light border-start border-end"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className="rounded-circle header-profile-user"
                  src={require('../src/images/avatar-1.jpg')}
                  alt="Header Avatar"
                />
                <span className="d-none d-xl-inline-block mx-1 fw-medium">
                Coinone Admin
                </span>
                <i
                  className="fa-solid fa-chevron-down"
                  style={{ fontSize: 9 }}
                />
              </button>
              <div className="dropdown-menu dropdown-menu-end w-100">
              
                <a className="dropdown-item" href="/Auth/Login">
                  <i className="fa-solid fa-right-from-bracket me-1" /> Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

    <div class="vertical-menu">

    <div data-simplebar class="h-100">

      {/* <!--- Sidemenu --> */}




      <div id="sidebar-menu" >





        {/* <!-- Left Menu Start --> */}
        <ul class="metismenu list-unstyled" id="side-menu"  >







          <li>
            <Link to={'/admin'} style={{display:'contents'}}>
            <a href="">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
              <i data-feather="home"></i>
              <span data-key="t-dashboard">Dashboard</span>
            </a>
            </Link>
          </li>
          {/* <div id="sidebar-menu"> */}

{/* </div> */}
          <li className={openMenus.lead? `mm-active`:''}>
            <a href="javascript: void(0);" class="has-arrow mm-active" onClick={() => toggleMenu('lead')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-book"><path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path><path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path></svg>
              <i data-feather="book"></i>
              <Link style={{display:'contents'}} to={'/listcontact'}><span data-key="t-apps">Contact</span></Link>
             
              {/* <i class="fa-solid fa-chevron-right " style={{ float: 'right', fontSize: '9px' }}></i> */}
            </a>




          </li>
          {/* <li className={openMenus.employee? 'mm-active':''}>
            <a href="javascript: void(0);" class="has-arrow " onClick={() => toggleMenu('employee')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
              <i data-feather="users"></i>

              <span data-key="t-apps">Event Master</span>
              <i class="fa-solid fa-chevron-right " style={{ float: 'right', fontSize: '9px' }}></i>
            </a>


            <ul className={`sub-menu ${openMenus.employee ? 'mm-show' : ''}`} aria-expanded={openMenus.employee}>
              <li>
                <a href="addemployee.html">
                  <span data-key="t-calendar">Add Employee</span>

                </a>
              </li>
              <li>
                <a href="viewemployees.html">
                  <span data-key="t-calendar">View Employees</span>

                </a>
              </li>

            </ul>

          </li>
          <li className={openMenus.branch?'mm-active':''}>
            <a href="javascript: void(0);" class="has-arrow" onClick={() => toggleMenu('branch')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
              <i data-feather="archive"></i>

              <span data-key="t-apps">Branch</span>
              <i class="fa-solid fa-chevron-right " style={{ float: 'right', fontSize: '9px' }}></i>
            </a>


            <ul className={`sub-menu ${openMenus.branch ? 'mm-show' : ''}`} aria-expanded={openMenus.branch}>
              <li>
                <a href="addbranch.html">
                  <span data-key="t-calendar">Add Branch</span>

                </a>
              </li>
              <li>
                <a href="list_branchs.html">
                  <span data-key="t-calendar">View Branches</span>

                </a>
              </li>
            </ul>

          </li>
          <li className={openMenus.product?'mm-active':''}>
            <a href="javascript: void(0);" class="has-arrow" onClick={() => toggleMenu('product')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
              <i data-feather="box"></i>

              <span data-key="t-apps">Product</span>
              <i class="fa-solid fa-chevron-right " style={{ float: 'right', fontSize: '9px' }}></i>
            </a>


            <ul className={`sub-menu ${openMenus.product ? 'mm-show' : ''}`} aria-expanded={openMenus.product}>
              <li>
          
               <a>
                  <span data-key="t-calendar">Add Product</span>

                </a>
                
              </li>
              <li>
              
                <a href="list_products.html">
                  <span data-key="t-calendar">View Products</span>
            
              </li>
            </ul>

          </li>
          <li className={openMenus.project?'mm-active':''}>
            <a href="javascript: void(0);" class="has-arrow" onClick={() => toggleMenu('project')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-database"><ellipse cx="12" cy="5" rx="9" ry="3"></ellipse><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path></svg>
              <i data-feather="database"></i>

              <span data-key="t-apps">Project</span>
              <i class="fa-solid fa-chevron-right " style={{ float: 'right', fontSize: '9px' }}></i>
            </a>


            <ul className={`sub-menu ${openMenus.project ? 'mm-show' : ''}`} aria-expanded={openMenus.project}>
              <li>
                <a href="addproject.html">
                  <span data-key="t-calendar">Add Project</span>
                </a>
              </li>
              <li>
                <a href="projects.html">
                  <span data-key="t-calendar">View Projects</span>
                </a>
              </li>
            </ul>

          </li>


          <li className={openMenus.client?'mm-active':''}>
            <a href="javascript: void(0);" class="has-arrow" onClick={() => toggleMenu('client')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
              <i data-feather="user"></i>

              <span data-key="t-apps">Client</span>
              <i class="fa-solid fa-chevron-right " style={{ float: 'right', fontSize: '9px' }}></i>
            </a>


            <ul className={`sub-menu ${openMenus.client ? 'mm-show' : ''}`} aria-expanded={openMenus.client}>
              <li>
                <a href="addclient.html">
                  <span data-key="t-calendar">Add Client</span>
                </a>
              </li>
              <li>
                <a href="list_clients.html">
                  <span data-key="t-calendar">View Clients</span>
                </a>
              </li>
            </ul>

          </li> */}



        </ul>
      </div>

      {/* <!-- Sidebar --> */}
    </div>
  </div>


      <div class="main-content">
          <div class="page-content">




            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0 font-size-18">Dashboard</h4>

                    <div class="page-title-right">
                      <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <i class="fa-solid fa-chevron-right " style={{ margin: '6px 5px 0px 5px', fontSize: '9px' }}></i>
                        <li class="breadcrumb-item active">Dashboard</li>
                      </ol>
                    </div>

                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}


              <div class="row">
                <div class="col-xl-4 col-md-6">
                  {/* <!-- card --> */}
                  <div class="card card-h-100">
                    {/* <!-- card body --> */}
                    <div class="card-body bg-b-purple">
                      <div class="row align-items-center">
                        <div class="col-8">
                          <span class="text-white mb-3 d-block text-truncate">Total Enquiry</span>
                          <h4 class="mb-3">
                            
      <span class="counter-value text-white" data-target="0" >{data.count}</span> 
                            
                         
                    
                           
                          </h4>
                        </div>
                        <div class="col-4 icon">
                        <i class="bi bi-file-earmark-richtext"></i>
                          {/* <i class="fa fa-bell"></i> */}
                        </div>

                      </div>
                  
                    </div>
                    {/* <!-- end card body --> */}
                  </div>
                  {/* <!-- end card --> */}
                </div>
                {/* <!-- end col --> */}

                <div class="col-xl-4 col-md-6">
                  {/* <!-- card --> */}
                  <div class="card card-h-100">
                    {/* <!-- card body --> */}
                    <div class="card-body bg-b-secondary">
                      <div class="row align-items-center">
                        <div class="col-8">
                          <span class="text-white mb-3 d-block text-truncate">Total Events</span>
                          <h4 class="mb-3">
                            <span class="counter-value text-white" data-target="4">4</span>
                          </h4>
                        </div>
                        <div class="col-4 icon">
                          {/* <i class="fa fa-bell"></i> */}
                          <i class="bi bi-calendar-event"></i>
                        </div>
                      </div>
                
                    </div>
                    {/* <!-- end card body --> */}
                  </div>
                  {/* <!-- end card --> */}
                </div>
                {/* <!-- end col--> */}

                <div class="col-xl-4 col-md-6">
                  {/* <!-- card --> */}
                  <div class="card card-h-100">
                    {/* <!-- card body --> */}
                    <div class="card-body bg-b-success">
                      <div class="row align-items-center">
                        <div class="col-8">
                          <span class="text-white mb-3 d-block text-truncate">Total Uploads</span>
                          <h4 class="mb-3">
                            <span class="counter-value text-white" data-target="0">0</span>
                          </h4>
                        </div>
                        <div class="col-4 icon">
                          {/* <i class="fa fa-bell"></i> */}
                          <i class="bi bi-cloud-upload"></i>
                        </div>
                      </div>

                    </div>
                    {/* <!-- end card body --> */}
                  </div>
                  {/* <!-- end card --> */}
                </div>
                {/* <!-- end col --> */}

                {/* <!-- end col --> */}
              </div>


            </div>


          </div>

          <footer class="footer">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12 text-center">
                  © Well Scaffolding CRM.Design & Develop by <a href="#!" class="text-decoration-underline">Coinone</a>
                </div>
              </div>
            </div>
          </footer>
        </div>
    </div>

    
  );
}

export default Dashboard;
